import capitalize from 'lodash/capitalize';
import React from 'react';
import { Badge } from "reactstrap";
import { getProviderConfig } from './ProviderConfig';
import { PROVIDERS } from './constants';

const getBadgeColor = (priorityLevel) => {
  switch (priorityLevel) {
    case 'emergency':
    case 'critical':
    case 'high':
      return 'danger';
    case 'medium':
      return 'warning';
    case 'low':
      return 'secondary';
    default:
      return 'secondary';
  }
};

const TicketPriorityBadge = ({ provider, priority }) => {
  const config = getProviderConfig(provider);
  const item = config.getTicketPriority(priority);

  if (!item) return null;

  const badgeColor = getBadgeColor(item.level.toLowerCase());
  const badgeLabel = provider === PROVIDERS.LIGHTEDGE ? capitalize(item.name) : item.name;
  return <Badge color={badgeColor}>{badgeLabel}</Badge>;
};

export default TicketPriorityBadge;