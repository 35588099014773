import React from 'react';
import { getProviderConfig } from './ProviderConfig';

const getTextColor = (priorityLevel) => {
  switch (priorityLevel) {
    case 'emergency':
    case 'critical':
    case 'high':
      return '#E84A50';
    case 'medium':
      return '#FEBE10';
    case 'low':
      return '#8798AD';
    default:
      return '#8798AD';
  }
}

export const TicketPriority = ({ provider, priority }) => {
  const config = getProviderConfig(provider);
  const item = config.getTicketPriority(priority);

  if (!item) return null;

  const textColor = getTextColor(item.level.toLowerCase());

  return (
    <span style={{ color: textColor }}>
      {item.name}
    </span>
  )
}
export default TicketPriority;