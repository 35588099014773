import axios from "axios";

const base_api = 'https://demo.tria.connectria.com';


const CommonService = {
  getResProperties: function (orgId, params = {}) {
    return axios.get(base_api + "/plugin/tria_interface/resource_properties/" + orgId, params);
  },
  setResProperty: function (params) {
    console.log(params)
    return axios.post(base_api + '/plugin/tria_interface/set_resource_property', params)
  },
  addCloud: function (params) {
    return axios.post(base_api + '/v2/prototype/cloud/add', params)
  },
  status: function (params) {
    return axios.get(base_api + '/Status', params)
  },
  statusOk: async function (params) {
    try {
      let res = await axios.get(base_api + '/Status', params);
      return res.data === '<STATUS>OK</STATUS>'
    } catch {
      return false;
    }
  }
};

export default CommonService;