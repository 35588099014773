import React from 'react';
import { Badge } from "reactstrap";
import { getProviderConfig } from './ProviderConfig';

export function getTicketStatusClassName({ provider, status }) {
  const config = getProviderConfig(provider);
  const lowerCaseStatus = status.toLowerCase();
  const statusDetails = config.ticketStatusMap[lowerCaseStatus] || config.defaultStatus;

  return `ticket-${statusDetails.label.replace(/\s+/g, '')}` || '';
}

const TicketStatusBadge = ({ provider, status }) => {
  const config = getProviderConfig(provider);
  const lowerCaseStatus = status.toLowerCase();
  const badgeProps = config.ticketStatusMap[lowerCaseStatus] || config.defaultStatus;

  return <Badge color={badgeProps.color}>{badgeProps.label || status}</Badge>;
};

export default TicketStatusBadge;