import axios from "axios";

const base_api = 'https://demo.tria.connectria.com';


const UserService = {
  cognitoDivvyAuth: function(token, customer_id) {
    const data = {token: token, customer_id: customer_id}
    return axios.post(base_api + "/plugin/tria_interface/cognito/auth", data);
  },
  login: function (email, password) {
    let request = axios.post(
      base_api + "/v2/public/user/login",
      {
        username: email,
        password: password
      }
    );
    return request;
  },
  user_info: function (options = {}) {
    let request = axios.get(
      base_api + "/v2/public/user/info",
      options
    );
    return request;
  },
  logout: function () {
    return axios.post(base_api + "/v2/public/user/logout");
  },
  enable_mfa: function (payload) {
    return axios.post(base_api + "/v2/public/user/tfa_enable", payload);
  },
  disable_mfa: function (payload) {
    return axios.post(base_api + "/plugin/tria_interface/user/tfa_disable", payload);
  },
  set_mfa_required: function (payload) {
    return axios.post(base_api + "/plugin/tria_interface/user/set_tfa_required", payload);
  },
  iniate: function (username) {
    return axios.post(base_api + "/v3/auth/initiate", {username: username});
  },
  local_login: function (password, token) {
    return axios.post(base_api + "/v3/auth/provider/local", {password: password, token: token});
  },
  verify_mfa: function (mfa_code, token) {
    return axios.post(base_api + "/v3/auth/tfa/verify", {tfa_token: mfa_code, token: token});
  },
  get_session_timeout_time: function () {
    return axios.get(base_api + "/plugin/tria_interface/session/expiration");
  },
  save_user_session: function () {
    return axios.get(base_api + "/plugin/tria_interface/session/save");
  }
};

export default UserService;